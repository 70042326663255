import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "author": "nateShoemaker",
  "categories": ["lambda", "ruby"],
  "date": "2020-08-06",
  "path": "/blog/lambda-and-active-storage",
  "summary": "Integrating AWS Lambda with ActiveStorage.",
  "title": "Ruby on Lambda: Integrating with ActiveStorage",
  "image": "./active_storage.png",
  "seo": {
    "og": {
      "type": "article",
      "title": "Ruby on Lambda: Integrating with ActiveStorage",
      "description": "Integrating AWS Lambda with ActiveStorage"
    },
    "twitter": {
      "card": "summary_large_image",
      "site": "@hintmedia"
    }
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><em parentName="p">{`This article is part of our Ruby on AWS Lambda blog series. A recent project had us migrating an existing PDF document processing system from Rails Sidekiq to AWS Lambda. The processing includes OCR, creating preview images, splicing the PDF, and more. Moving to Lambda reduced processing time by 300 times in some cases.`}</em></p>
    <p><em parentName="p">{`This series of articles will serve less as a step-by-step process to get OCR serverless infrastructure up and running and more of a highlight reel of our "Aha!" moments. In part one, we talk about creating a AWS Lambda Layer with Docker. In part two, we chatted about architecting a serverless app. In part three, we went through some strategies surrounding deployment. Here in part four, we'll investigate what's needed to integrate a Rails app that uses Active Storage with AWS Lambda. Check out the other posts in the series:`}</em></p>
    <ul>
      <li parentName="ul"><em parentName="li"><a parentName="em" {...{
            "href": "/blog/lambda-layer-dependencies"
          }}>{`Layer Dependencies`}</a></em></li>
      <li parentName="ul"><em parentName="li"><a parentName="em" {...{
            "href": "/blog/lambda-planning-and-architecture"
          }}>{`Planning & Architecting`}</a></em></li>
      <li parentName="ul"><em parentName="li"><a parentName="em" {...{
            "href": "/blog/aws-lambda-ship-it"
          }}>{`Package & Ship It!`}</a></em></li>
    </ul>
    <p><em parentName="p">{`Note: there are some assumptions made in this article regarding architecture and reading `}<a parentName="em" {...{
          "href": "/blog/lambda-planning-and-architecture"
        }}>{`Planning & Architecting`}</a>{` may be useful before diving in here.`}</em></p>
    <p>{`Performance was our main reason for moving from Sidekiq to AWS Lambda. We used Active Storage on a few models and wanted to continue using it after the migration. Specifically, we wanted to take advantage of Active Storage `}<a parentName="p" {...{
        "href": "https://api.rubyonrails.org/classes/ActiveStorage/Variant.html"
      }}>{`variants`}</a>{` via a GraphQL API and React frontend. The app had multiple views that requested all of the variants at once, and because the variants are created at the time of the request, the user experience under Sidekiq was very poor. What if we could create all of those variants on Lambda as we processed the document? It sounded plausible to us, so we spiked on it.`}</p>
    <p>{`Note that we'll be touching on specific pieces of code that directly relate to the integration; this won't be a tutorial on how to use Active Storage or setup AWS Lambda. We are using S3 as our storage service, and will be referencing it as a standalone entity. Let's dive in!`}</p>
    <h2>{`Uploading`}</h2>
    <p>{`Instead of passing form params to a model, we’ll take more of a manual approach. In the controller:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token variable"
          }}>{`@document`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`file`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`upload`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`file`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`We have a `}<inlineCode parentName="p">{`Document`}</inlineCode>{` model with `}<inlineCode parentName="p">{`has_one_attached :file`}</inlineCode>{`. This allows the file to be uploaded to S3 without creating the backing Active Storage record. Delaying that allows us to process the document on Lambda, create the needed variants, and respond back with data that will be used to create those records.`}</p>
    <p>{`Lambda has no knowledge of the Rails app or Active Storage, but if we can point it to the file we just uploaded, it can reference and process it. After a `}<inlineCode parentName="p">{`Document`}</inlineCode>{` has been created, we send `}<inlineCode parentName="p">{`document.file.key`}</inlineCode>{` to the vent function (LINK TO PLANNING AND ARCHITECTURE POST) that starts the processing work. The document’s key is the name of the file on S3. On Lambda, the real fun begins.`}</p>
    <h2>{`Variant Creation`}</h2>
    <p>{`Essentially, we needed to trick Active Storage. When requesting a variant, Active Storage will look for `}<inlineCode parentName="p">{`variant.jpg`}</inlineCode>{` in a specific location on S3:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token string"
          }}>{`"variants/`}<span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token delimiter tag"
              }}>{`#{`}</span>{`key`}<span parentName="span" {...{
                "className": "token delimiter tag"
              }}>{`}`}</span></span>{`/`}<span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token delimiter tag"
              }}>{`#{`}</span>{`variant_key`}<span parentName="span" {...{
                "className": "token delimiter tag"
              }}>{`}`}</span></span>{`"`}</span></code></pre></div>
    <p><inlineCode parentName="p">{`key`}</inlineCode>{` is the original file's key (`}<inlineCode parentName="p">{`document.file.key`}</inlineCode>{`), and `}<inlineCode parentName="p">{`variant_key`}</inlineCode>{` is a hash derived from the variant's transformation options (e.g. `}<inlineCode parentName="p">{`{auto_orient: true, resize: '1000x1000'}`}</inlineCode>{`) and the Rails app's `}<inlineCode parentName="p">{`ENV['SECRET_KEY_BASE']`}</inlineCode>{`. Let's look at the Lambda  code:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token method-definition"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`create_variant`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`image`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` key`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` transformation`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
 `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# create message verifier and variant key`}</span>{`
 verifier_key `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` verifier`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`generate`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`transformation`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` purpose`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:variation`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
 variant_key `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Digest`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`SHA256`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`hexdigest`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`verifier_key`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
 
 tmp `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Tempfile`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'variant.jpg'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
 
 `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`MiniMagick`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`Tool`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`Convert`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`do`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`convert`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`
   convert `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{` image`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`path
   `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# apply transformations here, like:`}</span>{`
   `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# convert.auto_orient`}</span>{`
   `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# convert.resize transformation[:resize]`}</span>{`
   convert `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{` tmp`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`path
 `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
 
 s3 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Aws`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`S3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`Client`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{`
 s3`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`put_object`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
   bucket`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`       `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"some-cool-bucket"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
   key`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`          `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"variants/`}<span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token delimiter tag"
              }}>{`#{`}</span>{`key`}<span parentName="span" {...{
                "className": "token delimiter tag"
              }}>{`}`}</span></span>{`/`}<span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token delimiter tag"
              }}>{`#{`}</span>{`variant_key`}<span parentName="span" {...{
                "className": "token delimiter tag"
              }}>{`}`}</span></span>{`"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
   body`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`         tmp`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
   content_type`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'image/jpeg'`}</span>{`
 `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span></code></pre></div>
    <p><inlineCode parentName="p">{`#create_variant`}</inlineCode>{` takes a `}<inlineCode parentName="p">{`File`}</inlineCode>{`, `}<inlineCode parentName="p">{`document.file.key`}</inlineCode>{`, and the transformation options as arguments. The `}<inlineCode parentName="p">{`variant_key`}</inlineCode>{` is created (we'll look at what `}<inlineCode parentName="p">{`verifier`}</inlineCode>{` is soon), and the actual image conversion is handled with ImageMagick. Note the actual ImageMagick conversions depend on the transformation options being passed in. For an example I used the options that were previously mentioned, `}<inlineCode parentName="p">{`{auto_orient: true, resize: '1000x1000'}`}</inlineCode>{`. After the image is processed, we then place it on S3, using the specific path Active Storage expects.`}</p>
    <p>{`Figuring out how to create the `}<inlineCode parentName="p">{`verifier_key`}</inlineCode>{` was quite difficult. It took a late night fueled by coffee and spelunking into the bowels of Active Storage, but it proved fruitful. Let's take a look at `}<inlineCode parentName="p">{`#verifier`}</inlineCode>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token method-definition"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`verifier`}</span></span>{`
 key_generator `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`ActiveSupport`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`CachingKeyGenerator`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
   `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`ActiveSupport`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`KeyGenerator`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`ENV`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'SECRET_KEY_BASE'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` iterations`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1000`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
 `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
 key `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` key_generator`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`generate_key`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'ActiveStorage'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
 `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`ActiveSupport`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`MessageVerifier`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`key`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span></code></pre></div>
    <p>{`Active Storage was created by people much smarter than I, and I'll be the first to admit that I did not look at `}<em parentName="p">{`all`}</em>{` the implementation details surrounding `}<inlineCode parentName="p">{`ActiveSupport::CachingKeyGenerator`}</inlineCode>{`,  than I, and I'll be the first to admit that I did not look at `}<em parentName="p">{`all`}</em>{` the implementation details surrounding `}<inlineCode parentName="p">{`ActiveSupport::KeyGenerator`}</inlineCode>{`, `}<inlineCode parentName="p">{`ActiveSupport::MessageVerifier`}</inlineCode>{`. However, their names are descriptive and give us a good bird's-eye view. A key is generated from your Rails app's `}<inlineCode parentName="p">{`SECRET_KEY_BASE`}</inlineCode>{`, and that key is then turned into a digest that is used to create the path to the variant. A few notes:`}</p>
    <ul>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`iterations`}</inlineCode>{` option when instantiating `}<inlineCode parentName="li">{`ActiveSupport::KeyGenerator`}</inlineCode>{` does not have to be 1,000. I, of course, didn't test `}<em parentName="li">{`every`}</em>{` number, but the natural numbers I tested all had the same result. Active Storage uses 1,000, so I thought I would too.`}</li>
      <li parentName="ul">{`The argument passed to `}<inlineCode parentName="li">{`#generate_key`}</inlineCode>{` is the salt, and for our intended purposes on Lambda it can be any string.`}</li>
    </ul>
    <h2>{`Data for the `}<inlineCode parentName="h2">{`active_storage_attachments`}</inlineCode>{` record`}</h2>
    <p>{`Early on in the spike, we hit a lot of `}<inlineCode parentName="p">{`ActiveStorage::IntegrityError`}</inlineCode>{`s. While you can find yourself raising this error on accident in many scenarios, the reason why is always the same: the hashed contents of the file don't match what's stored in the database. Since we uploaded the file straight to S3, we purposefully didn't create a record in `}<inlineCode parentName="p">{`active_storage_attachments`}</inlineCode>{`, and don't have the reference to the file anymore without downloading it (which would be a waste). We had to create the digest on Lambda while we still had a reference to the file.`}</p>
    <p>{`Here's the data we collect for each page in a document (note that `}<inlineCode parentName="p">{`file`}</inlineCode>{` is a `}<inlineCode parentName="p">{`File`}</inlineCode>{` object):`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
 content_md5`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`  `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Digest`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`MD5`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`file`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`file`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`base64digest`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
 byte_size`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`    `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`File`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`size`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`file`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`to_s`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
 key`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`          key`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
 content_type`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` content_type
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`After every page is processed, our sink function collects all the data and sends a JSON response back to the Rails app.`}</p>
    <h2>{`All together now`}</h2>
    <p>{`When the Rails app receives the JSON, Sidekiq workers are started that process the response. Part of the workers' responsibility is to create a record in `}<inlineCode parentName="p">{`active_storage_attachments`}</inlineCode>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token method-definition"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`create_blob`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`data`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
 params `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
   key`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`          data`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'key'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
   filename`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`     data`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'key'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
   checksum`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`     data`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'content_md5'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
   byte_size`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`    data`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'byte_size'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
   content_type`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` data`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'content_type'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
 `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
 
 `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`ActiveStorage`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`Blob`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`create_before_direct_upload`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`params`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span></code></pre></div>
    <p><a parentName="p" {...{
        "href": "https://api.rubyonrails.org/classes/ActiveStorage/Blob.html#method-c-create_before_direct_upload-21"
      }}><inlineCode parentName="a">{`#create_before_direct_upload!`}</inlineCode></a>{` allows us to create the record and `}<em parentName="p">{`not`}</em>{` upload the file to S3 as it already exists there. Tricking Active Storage, and all.`}</p>
    <p>{`And with that, Active Storage works just as it normally would. With our implementation, however, we process `}<em parentName="p">{`all`}</em>{` variants in parallel, improving load times and user experience for everyone!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      